<template>
  <div style="height: 100%;background-color: #ffffff">
    <head-layout
      :head-title="headTitle"
      :head-btn-options="headBtnOptions"
      @head-save="headSave(false)"
      @head-saveBack="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>
    <div class="formContentBox">
      <div class="formMain">
        <div>
          <div style="padding: 0 12px">
            <el-form ref="dataForm" :model="dataForm" label-width="150px" :disabled="formType == 'view'"
                     :rules="rules">
              <div class="formTopic" style="margin-bottom: 12px">基本信息</div>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="任务编码" prop="taskCode">
                    <el-input v-model="dataForm.taskCode" disabled placeholder="系统自动生成"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="任务名称" prop="taskName">
                    <el-input v-model="dataForm.taskName" placeholder="请输入任务名称" maxlength="50"
                              show-word-limit></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="状态" prop="acquisitionStatus">
                    <el-select v-model="dataForm.acquisitionStatus" placeholder="请选择状态" disabled>
                      <el-option
                        v-for="item in publishStatusOption"
                        :key="item.dictKey"
                        :label="item.dictValue"
                        :value="item.dictKey"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="编制人" prop="weaveUser">
                    <el-input v-model="dataForm.weaveUser" disabled placeholder="系统自动生成"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="编制单位" prop="weaveDept">
                    <el-input v-model="dataForm.weaveDept" disabled placeholder="系统自动生成"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="模板来源" prop="templateClassify">
                    <el-select v-model="dataForm.templateClassify" placeholder="请选择模板来源" :clearable="false"
                               @change="chooseTemplateClassify">
                      <el-option
                        v-for="item in templateClassifyOption"
                        :key="item.dictKey"
                        :label="item.dictValue"
                        :value="item.dictKey">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="模板名称" prop="templateCode" v-if="dataForm.templateClassify == 1">
                    <el-select v-model="dataForm.templateCode" placeholder="请选择模板名称" clearable
                               @change="chooseTemplate">
                      <el-option
                        v-for="item in templateOptions"
                        :key="item.templateCode"
                        :label="item.templateName"
                        :value="item.templateCode">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="模板名称" prop="templateCode" v-if="dataForm.templateClassify == 2">
                    <el-select v-model="dataForm.templateCode" placeholder="请选择模板名称" clearable
                               @change="chooseTemplate">
                      <el-option
                        v-for="item in reportTemplateOption"
                        :key="item.id"
                        :label="item.reportName"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <!--              <div class="formTopic" style="margin-bottom: 12px">填报要求</div>-->
              <el-tabs v-model="activeName" type="card">
                <el-tab-pane label="填报要求" name="require"></el-tab-pane>
                <el-tab-pane label="填报单位" name="unit"></el-tab-pane>
              </el-tabs>
              <div v-show="activeName == 'require'" style="margin-top: 12px">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="标题：" prop="title">
                      <el-input v-model="dataForm.title" placeholder="请输入标题" maxlength="50" show-word-limit>
                      </el-input>
                    </el-form-item
                    >
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="角色：" prop="roleId">
                      <el-select v-model="dataForm.roleId" multiple placeholder="请选择角色" @change="chooseRole">
                        <el-option
                          v-for="item in roleData"
                          :key="item.id"
                          :label="item.roleName"
                          :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item
                    >
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="采集周期：" prop="acquisitionCycleType">
                      <el-radio-group v-model="dataForm.acquisitionCycleType" @input="chooseCycle">
                        <el-radio :label="item.dictKey" v-for="(item,index) in acquisitionCycleOption" :key="index">
                          {{ item.dictValue }}
                        </el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12" v-if="dataForm.acquisitionCycleType == 2">
                    <el-form-item label="采集日期：" prop="acquisitionCycle">
                      <el-select v-model="dataForm.acquisitionCycle" @change="chooseCycWeek" placeholder="请选择采集日期">
                        <el-option
                          v-for="item in weekOptions"
                          :key="item.dictKey"
                          :label="item.dictValue"
                          :value="item.dictKey"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12" v-if="dataForm.acquisitionCycleType == 3">
                    <el-form-item label="采集日期：" prop="acquisitionCycle">
                      <el-select v-model="dataForm.acquisitionCycle" @change="chooseCycMonth" placeholder="请选择采集日期">
                        <el-option
                          v-for="item in dayOptions"
                          :key="item.dictKey"
                          :label="item.dictValue"
                          :value="item.dictKey">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12" v-if="dataForm.acquisitionCycleType == 4">
                    <el-form-item label="采集日期：" prop="acquisitionCycle">
                      <el-select v-model="month" placeholder="请选择月份" @change="chooseYearMonth"
                                 style="width: 50% !important;">
                        <el-option
                          v-for="item in monthOptions"
                          :key="item.dictKey"
                          :label="item.dictValue"
                          :value="item.dictKey">
                        </el-option>
                      </el-select>
                      <el-select v-model="day" placeholder="请选择日份" style="width: 50% !important;">
                        <el-option
                          v-for="item in yearDayOption"
                          :key="item.dictKey"
                          :label="item.dictValue"
                          :value="item.dictKey">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row v-if="dataForm.acquisitionCycleType == 5">
                  <el-col :span="12">
                    <el-form-item label="填报截至日期：" prop="deadlineFillingDate">
                      <el-date-picker
                        v-model="dataForm.deadlineFillingDate"
                        type="datetime"
                        format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        default-time="23:59:59"
                        placeholder="请选择填报截至日期"
                        @change="handleDateChange"
                      >
                      </el-date-picker>
                    </el-form-item
                    >
                  </el-col>
                </el-row>
                <el-row v-if="dataForm.acquisitionCycleType == 5">
                  <el-col :span="12">
                    <el-form-item label="汇总截至日期：" prop="deadlineSummaryDate">
                      <el-date-picker
                        v-model="dataForm.deadlineSummaryDate"
                        type="datetime"
                        format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        default-time="23:59:59"
                        placeholder="请选择汇总截至日期"
                        @change="handleDateChange"
                      >
                      </el-date-picker>
                    </el-form-item
                    >
                  </el-col>
                </el-row>
                <el-row v-if="dataForm.acquisitionCycleType != 5">
                  <el-col :span="12">
                    <el-form-item label="通知时间：" prop="notificationTime">
                      <el-time-picker
                        arrow-control
                        v-model="dataForm.notificationTime"
                        format="HH:mm:ss"
                        value-format="HH:mm:ss"
                        placeholder="请选择通知时间">
                      </el-time-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row v-if="dataForm.acquisitionCycleType != 5">
                  <!--每周-->
                  <el-col :span="12" v-if="dataForm.acquisitionCycleType == 2">
                    <el-form-item label="填报截止日期：" prop="fillWeek">
                      <el-popover
                        width="400"
                        ref="fillPopover"
                        trigger="manual"
                        @show="openFillPopover"
                        @hide="closeFillPopover"
                        v-model="fillWeekShow">
                        <div class="weekBigBox">
                          <div class="leftWeek">
                            <div class="thisWeek" :style="{'color': thisWeekActive == index ? themeColor : ''}"
                                 @click="chooseThisWeek(item,index)" v-for="(item,index) in thisWeekAndNext"
                                 :key="index">
                              {{ item.title }}
                            </div>
                          </div>
                          <div class="rightWeek">
                            <div class="weekLittleBox" :style="{'color': weekLittleActive == index ? themeColor : ''}"
                                 @click="chooseWeek(item,index)" v-for="(item,index) in thisWeekOptions" :key="index">
                              {{ item.dictValue }}
                            </div>
                          </div>
                        </div>
                        <el-button slot="reference" style="width: 100%;text-align: left" @click="openFillWeek">
                          {{ fillWeekText }}{{ fillDayText }}
                        </el-button>
                      </el-popover>
                    </el-form-item>
                  </el-col>
                  <!--每月-->
                  <el-col :span="12" v-if="dataForm.acquisitionCycleType == 3">
                    <el-form-item label="填报截止日期：" prop="fillWeek">
                      <el-popover
                        width="400"
                        ref="reportCycMonth"
                        trigger="manual"
                        @show="openReportCycMonthPopver"
                        @hide="closeReportCycMonthPopver"
                        v-model="reportMonthShow">
                        <div class="weekBigBox">
                          <div class="leftWeek">
                            <div class="thisWeek" :style="{'color': reportMonthNextActive == index ? themeColor : ''}"
                                 @click="chooseReportMonthNext(item,index)" v-for="(item,index) in reportMonthAndNext"
                                 :key="index">
                              {{ item.title }}
                            </div>
                          </div>
                          <div class="rightMonth">
                            <div class="weekLittleBox"
                                 :style="{'color': reportMonthDayActive == index ? themeColor : ''}"
                                 @click="chooseReportMonthDay(item,index)" v-for="(item,index) in reportMonthDayOption"
                                 :key="index">
                              {{ item.dictValue }}
                            </div>
                          </div>
                        </div>
                        <el-button slot="reference" style="width: 100%;text-align: left" @click="openReportMonth">
                          {{ reportMonthNextText }}{{ reportMonthDayText }}
                        </el-button>
                      </el-popover>
                    </el-form-item>
                  </el-col>
                  <!--每年-->
                  <el-col :span="12" v-if="dataForm.acquisitionCycleType == 4">
                    <el-form-item label="填报截止日期：" prop="fillWeek">
                      <el-popover
                        width="400"
                        ref="reportCycYear"
                        trigger="manual"
                        @show="openReportCycYearPopver"
                        @hide="closeReportCycYearPopver"
                        v-model="reportYearShow">
                        <div class="weekBigBox">
                          <div class="leftWeek">
                            <div class="thisWeek" :style="{'color': reportYearNextActive == index ? themeColor : ''}"
                                 @click="chooseReportYearNext(item,index)" v-for="(item,index) in reportYearAndNext"
                                 :key="index">
                              {{ item.title }}
                            </div>
                          </div>
                          <div class="middleMonth">
                            <div class="thisWeek" style="text-align: center"
                                 :style="{'color': reportYearMonthActive == index ? themeColor : ''}"
                                 @click="chooseReportYearMonth(item,index)"
                                 v-for="(item,index) in reportYearMonthOption" :key="index">
                              {{ item.dictValue }}
                            </div>
                          </div>
                          <div class="rightMonth" style="height: 308px;width: calc(100% - 220px)">
                            <div class="weekLittleBox"
                                 :style="{'color': reportYearDayActive == index ? themeColor : ''}"
                                 @click="chooseReportYearDay(item,index)" v-for="(item,index) in reportYearDayOption"
                                 :key="index">
                              {{ item.dictValue }}
                            </div>
                          </div>
                        </div>
                        <el-button slot="reference" style="width: 100%;text-align: left" @click="openReportYear">
                          {{ reportYearNextText }}{{ reportYearMonthText }}{{ reportYearDayText }}
                        </el-button>
                      </el-popover>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row v-if="dataForm.acquisitionCycleType != 5">
                  <!--每周-->
                  <el-col :span="12" v-if="dataForm.acquisitionCycleType == 2">
                    <el-form-item label="汇总截止日期：" prop="summaryWeek">
                      <el-popover
                        width="400"
                        trigger="manual"
                        ref="sumPopover"
                        @show="openSumPopover"
                        @hide="closeSumPopover"
                        v-model="sumWeekShow">
                        <div class="weekBigBox">
                          <div class="leftWeek">
                            <div class="thisWeek" :style="{'color': thisSumWeekActive == index ? themeColor : ''}"
                                 @click="chooseThisSumWeek(item,index)" v-for="(item,index) in thisSumWeekAndNext"
                                 :key="index">
                              {{ item.title }}
                            </div>
                          </div>
                          <div class="rightWeek">
                            <div class="weekLittleBox"
                                 :style="{'color': weekSumLittleActive == index ? themeColor : ''}"
                                 @click="chooseSumWeek(item,index)" v-for="(item,index) in thisSumWeekOptions"
                                 :key="index">
                              {{ item.dictValue }}
                            </div>
                          </div>
                        </div>
                        <el-button slot="reference" style="width: 100%;text-align: left" @click="openSumWeek">
                          {{ sumWeekText }}{{ sumDayText }}
                        </el-button>
                      </el-popover>
                    </el-form-item>
                  </el-col>
                  <!--每月-->
                  <el-col :span="12" v-if="dataForm.acquisitionCycleType == 3">
                    <el-form-item label="汇总截止日期：" prop="summaryWeek">
                      <el-popover
                        width="400"
                        ref="sumCycMonth"
                        trigger="manual"
                        @show="openSumCycMonthPopver"
                        @hide="closeSumCycMonthPopver"
                        v-model="sumMonthShow">
                        <div class="weekBigBox">
                          <div class="leftWeek">
                            <div class="thisWeek" :style="{'color': sumMonthNextActive == index ? themeColor : ''}"
                                 @click="chooseSumMonthNext(item,index)" v-for="(item,index) in sumMonthAndNext"
                                 :key="index">
                              {{ item.title }}
                            </div>
                          </div>
                          <div class="rightMonth">
                            <div class="weekLittleBox" :style="{'color': sumMonthDayActive == index ? themeColor : ''}"
                                 @click="chooseSumMonthDay(item,index)" v-for="(item,index) in sumMonthDayOption"
                                 :key="index">
                              {{ item.dictValue }}
                            </div>
                          </div>
                        </div>
                        <el-button slot="reference" style="width: 100%;text-align: left" @click="openSumMonth">
                          {{ sumMonthNextText }}{{ sumMonthDayText }}
                        </el-button>
                      </el-popover>
                    </el-form-item>
                  </el-col>
                  <!--每年-->
                  <el-col :span="12" v-if="dataForm.acquisitionCycleType == 4">
                    <el-form-item label="汇总截止日期：" prop="summaryWeek">
                      <el-popover
                        width="400"
                        ref="sumCycYear"
                        trigger="manual"
                        @show="openSumCycYearPopver"
                        @hide="closeSumCycYearPopver"
                        v-model="sumYearShow">
                        <div class="weekBigBox">
                          <div class="leftWeek">
                            <div class="thisWeek" :style="{'color': sumYearNextActive == index ? themeColor : ''}"
                                 @click="chooseSumYearNext(item,index)" v-for="(item,index) in sumYearAndNext"
                                 :key="index">
                              {{ item.title }}
                            </div>
                          </div>
                          <div class="middleMonth">
                            <div class="thisWeek" style="text-align: center"
                                 :style="{'color': sumYearMonthActive == index ? themeColor : ''}"
                                 @click="chooseSumYearMonth(item,index)" v-for="(item,index) in sumYearMonthOption"
                                 :key="index">
                              {{ item.dictValue }}
                            </div>
                          </div>
                          <div class="rightMonth" style="height: 308px;width: calc(100% - 220px)">
                            <div class="weekLittleBox" :style="{'color': sumYearDayActive == index ? themeColor : ''}"
                                 @click="chooseSumYearDay(item,index)" v-for="(item,index) in sumYearDayOption"
                                 :key="index">
                              {{ item.dictValue }}
                            </div>
                          </div>
                        </div>
                        <el-button slot="reference" style="width: 100%;text-align: left" @click="openSumYear">
                          {{ sumYearNextText }}{{ sumYearMonthText }}{{ sumYearDayText }}
                        </el-button>
                      </el-popover>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="上传附件：">
                      <el-upload
                        class="upload-demo"
                        action="api/sinoma-resource/oss/endpoint/put-file"
                        :on-success="handleSuccess"
                        :on-remove="handleRemove"
                        :headers="headers"
                        multiple
                        :limit="1"
                        :file-list="fileList"
                      >
                        <el-button size="small" type="primary"
                        >点击上传
                        </el-button
                        >
                        <div slot="tip" class="el-upload__tip">
                          支持扩展名：.rar .zip .doc .docx .pdf .jpg...
                        </div>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="填写说明：">
                      <el-input v-model="dataForm.acquisitionDescription" type="textarea" maxlength="200"
                                show-word-limit>
                      </el-input>
                    </el-form-item
                    >
                  </el-col>
                </el-row>
              </div>
            </el-form>
            <!--            <div class="formTopic">填报单位</div>-->
            <div
              style="padding: 12px; display: flex; justify-content: center" v-show="activeName == 'unit'"
            >
              <el-checkbox-group v-model="groupRadio" size="small" @change="chooseGroup">
                <el-checkbox :label="item.groupName" border v-for="(item,index) in groupOption"
                             :key="index"></el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="shuttleBackBox" v-show="activeName == 'unit'">
              <div class="shutleBack">
                <div class="shutleTitle">组织架构</div>
                <div class="shutContent">
                  <el-tree
                    v-if="deptTreeShow"
                    ref="tree"
                    :data="deptChildTreeData"
                    :props="defaultProps"
                    node-key="id"
                    :show-checkbox="formType != 'view'"
                    :check-strictly="true"
                    :default-checked-keys="defaultCheckedKeys"
                    @check-change="handleCheckChange"
                  >
                    <template class="custom-tree-node" slot-scope="{ node }">
                      <el-tooltip :content="node.label" placement="bottom">
                        <span class="text">{{ node.label }}</span>
                      </el-tooltip>
                    </template>
                  </el-tree>
                </div>
              </div>
              <div class="shutleBack" style="width: 66%">
                <div class="unitBox">
                  <div class="unitTitle" :style="{'color': unitActive == index ? themeColor : ''}"
                       v-for="(item,index) in unitList" :key="index" @click="chooseUnit(index)">{{ item }}
                  </div>
                </div>
                <div class="shutContent">
                  <div
                    class="deptBox"
                    v-for="(item, index) in middleShutData"
                    :key="index"
                    v-if="unitActive == 0"
                  >
                    <span>{{ item.organize }}</span><span v-if="item.userNameList != ''"
                                                          style="color: #2979FF">【{{ item.userNameList }}】</span>
                  </div>
                  <div
                    class="deptBox"
                    v-for="(item, index) in rightShutData"
                    :key="index"
                    v-if="unitActive == 1"
                  >
                    <span>{{ item.organize }}</span><span v-if="item.userNameList != ''"
                                                          style="color: #2979FF">【{{ item.userNameList }}】</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {attributeDetail, attributeSave, dictionaryBiz, dictionary, templateByEnable} from "@/api/reportTasks";
import {deptChildTree, mergeDeptUser, reportList} from '@/api/reportTemplate';
import {GettrainingDeptGroup, DeptGroupDetail} from "@/api/safe";
import {mapGetters} from "vuex";
import {getUserInfo} from "@/api/system/user";
import Template from "../../../message/template/list.vue";

export default {
  components: {Template},
  data() {
    return {
      activeName: 'require',
      formType: '',
      groupRadio: [],
      headTitle: '',
      month: '',
      day: '',
      ids: '',
      names: '',
      namesM: "",
      dataForm: {
        id: '',
        taskCode: '',
        taskName: '',
        acquisitionStatus: '1',
        templateClassify: '1',
        weaveUser: '',
        weaveDept: '',
        templateCode: '',
        title: '',
        tableName: '',
        roleId: [],
        acquisitionCycle: '',
        acquisitionCycleType: '2',
        acquisitionTime: '',
        notificationTime: '09:00:00',
        reportingDay: '', // 填报周期时间范围
        // summaryDay: '', // 汇总周期时间范围
        reportingPeriod: '',
        summaryPeriod: '',
        acquisitionDescription: '',
        deadlineFillingDate: '',
        deadlineSummaryDate: '',
        isCycle: '1',
        summaryList: [],
        fillList: [],
        fillWeek: '',
        fillDay: '',
        summaryWeek: '',
        summaryDayEqs: '',
        fillMonth: '1',
        summaryMonth: '1'
      },
      reportingDay: [], // 填报周期时间范围
      summaryDay: [], // 汇总周期时间范围
      reportingByAfter: '', // 填报周期范围的最后一天
      defaultProps: {
        children: "children",
        label: "fullName",
      },
      unitList: ['填报单位', '汇总单位'],
      unitActive: 0,
      rules: {
        taskName: [
          {required: true, message: '请输入任务名称', trigger: 'blur'}
        ],
        acquisitionStatus: [
          {required: true, message: '请选择状态', trigger: 'change'}
        ],
        templateCode: [
          {required: true, message: '请选择模板名称', trigger: 'change'}
        ],
        templateClassify: [
          {required: true, message: '请选择模板分类', trigger: 'change'}
        ],
        title: [
          {required: true, message: '请输入标题', trigger: 'blur'}
        ],
        roleId: [
          {required: true, message: '请选择角色', trigger: 'change'}
        ],
        notificationTime: [
          {required: true, message: '请选择通知时间', trigger: 'change'}
        ],
        acquisitionCycleType: [
          {required: true, message: '请选择采集周期', trigger: 'change'}
        ],
        acquisitionTime: [
          {required: true, message: '请选择采集日期', trigger: 'change'}
        ],
        fillWeek: [
          {required: true, message: '请选择填报截至日期', trigger: 'blur'},
          // {validator: this.validateReportingPeriod, trigger: 'blur'},
        ],
        summaryWeek: [
          {required: true, message: '请选择汇总截至日期', trigger: 'blur'},
          // {validator: this.validateSummaryPeriod, trigger: 'blur'},
        ],
        deadlineFillingDate: [
          {required: true, message: '请选择填报截至日期', trigger: 'change'}
        ],
        deadlineSummaryDate: [
          {required: true, message: '请选择汇总截至日期', trigger: 'change'}
        ],
      },
      deptTreeShow: false,
      defaultCheckedKeys: [],
      fnDeptTree: [],
      fndeptChildTreeData: [],
      deptChildTreeData: [],
      middleShutData: [],
      rightShutData: [],
      templateOptions: [],
      publishStatusOption: [],
      groupOption: [],
      acquisitionCycleOption: [],
      weekOptions: [],
      dayOptions: [],
      templateClassifyOption: [],
      reportTemplateOption: [],
      mainDayOptions: [],
      yearDayOption:[],
      monthOptions: [],
      fillWeekText: '请选择填报截止时间',
      fillDayText: '',
      sumWeekText: '请选择汇总截止时间',
      sumDayText: '',
      thisWeekAndNext: [{
        title: '本周',
        value: '0'
      }, {
        title: '下周',
        value: '1'
      }],
      thisSumWeekAndNext: [{
        title: '本周',
        value: '0'
      }, {
        title: '下周',
        value: '1'
      }, {
        title: '下下周',
        value: '2'
      }],
      thisWeekOptions: [],
      thisSumWeekOptions: [],
      thisWeekActive: 0,
      weekLittleActive: null,
      thisSumWeekActive: 0,
      weekSumLittleActive: null,
      fillWeekShow: false,
      sumWeekShow: false,
      // 每月
      reportMonthShow: false,
      sumMonthShow: false,
      reportMonthAndNext: [{
        title: '本月',
        value: '0'
      }, {
        title: '下月',
        value: '1'
      }],
      reportMonthDayOption: [],
      sumMonthAndNext: [{
        title: '本月',
        value: '0'
      }, {
        title: '下月',
        value: '1'
      }, {
        title: '下下月',
        value: '2'
      }],
      sumMonthDayOption: [],
      reportMonthNextActive: 0,
      reportMonthDayActive: null,
      reportMonthNextText: '请选择填报截止时间',
      reportMonthDayText: '',
      sumMonthNextActive: 0,
      sumMonthDayActive: null,
      sumMonthNextText: '请选择汇总截止时间',
      sumMonthDayText: '',
      // 每年
      reportYearShow: false,
      sumYearShow: false,
      reportYearAndNext: [{
        title: '本年',
        value: '0'
      }, {
        title: '下年',
        value: '1'
      }],
      reportYearMonthOption: [],
      reportYearDayOption: [],
      sumYearAndNext: [{
        title: '本年',
        value: '0'
      }, {
        title: '下年',
        value: '1'
      }, {
        title: '下下年',
        value: '2'
      }],
      sumYearMonthOption: [],
      sumYearDayOption: [],
      reportYearNextActive: 0,
      reportYearMonthActive: 0,
      reportYearDayActive: null,
      reportYearNextText: '请选择填报截止时间',
      reportYearMonthText: '',
      reportYearDayText: '',
      sumYearNextActive: 0,
      sumYearMonthActive: 0,
      sumYearDayActive: null,
      sumYearNextText: '请选择汇总截止时间',
      sumYearMonthText: '',
      sumYearDayText: ''
    }
  },
  computed: {
    ...mapGetters(["deptTree", "roleData", "userInfo", "themeColor"]),
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.formType)) {
        result.push(
          {
            label: "保存",
            emit: "head-save",
            type: "button",
            btnOptType: "save",
          }
        );
        result.push(
          {
            label: "保存并返回",
            emit: "head-saveBack",
            type: "button",
            btnOptType: "saveBack",
          }
        );
      }
      result.push(
        {
          label: "取消",
          emit: "head-cancel",
          type: "button",
          btnOptType: "cancel",
        }
      );
      return result;
    },
  },
  created() {
    this.getDictory();
  },
  mounted() {
    this.reportingDay = null;
    this.fnDeptTree = this.fnTree(this.deptTree);
    this.headers = {
      "Sinoma-Auth": "bearer " + this.userInfo.access_token,
    };
    let type = this.$route.query.type;
    this.formType = type;
    if (type == 'add') {
      this.headTitle = '任务设置新增';
      this.getNowUserInfo();
      //this.getDeptChildTreeData();
    }
    if (type == 'edit') {
      this.headTitle = '任务设置编辑';
      this.dataForm.id = this.$route.query.id;
      this.getAttributeDetail();
    }
    if (type == 'view') {
      this.headTitle = '任务设置查看';
      this.dataForm.id = this.$route.query.id;
      this.getAttributeDetail();
    }
  },
  methods: {
    openFillPopover() {
      document.addEventListener('click', this.hideFillPopover, false)
    },
    closeFillPopover() {
      document.removeEventListener('click', this.hideFillPopover, false)
    },
    hideFillPopover(e) {
      if (!this.$refs.fillPopover.$el.contains(e.target)) {
        this.fillWeekShow = false
      }
    },
    openSumPopover() {
      document.addEventListener('click', this.hideSumPopover, false)
    },
    closeSumPopover() {
      document.removeEventListener('click', this.hideSumPopover, false)
    },
    hideSumPopover(e) {
      if (!this.$refs.sumPopover.$el.contains(e.target)) {
        this.sumWeekShow = false
      }
    },
    openFillWeek() {
      if (this.dataForm.acquisitionCycle == '') {
        this.$message.warning('请先选择采集时间');
        return;
      }
      this.fillWeekShow = true;
    },
    // 选择填报截至时间-周-(哪一周)
    chooseThisWeek(item, index) {
      this.thisWeekActive = index;
      this.dataForm.fillWeek = item.value;
      this.fillWeekText = this.thisWeekAndNext[index].title;

      this.dataForm.fillDay = '';
      this.fillDayText = '';
      this.weekLittleActive = null;

      this.dataForm.summaryWeek = '';
      this.sumWeekText = '请选择汇总周期';
      this.thisSumWeekActive = 0;

      this.dataForm.summaryDayEqs = '';
      this.sumDayText = '';
      this.weekSumLittleActive = null;

      if (index == 0 && this.dataForm.acquisitionCycle != 7) {
        this.thisWeekOptions = this.weekOptions.slice(this.dataForm.acquisitionCycle, this.weekOptions.length);
      } else {
        this.thisWeekOptions = this.weekOptions;
      }
    },
    // 选择填报截至时间-周-(哪一天)
    chooseWeek(item, index) {
      this.weekLittleActive = index;
      this.dataForm.fillDay = item.dictKey;
      this.fillDayText = '-' + this.thisWeekOptions[index].dictValue;

      if (this.weekLittleActive == this.thisWeekOptions.length - 1 && this.dataForm.fillWeek == 0) {
        if (this.thisWeekAndNext.length == 1) {
          this.thisSumWeekOptions = this.weekOptions;
          this.thisSumWeekAndNext = [{
            title: '下下周',
            value: '2'
          }];
        } else {
          this.thisSumWeekOptions = this.weekOptions;
          this.thisSumWeekAndNext = [{
            title: '下周',
            value: '1'
          }, {
            title: '下下周',
            value: '2'
          }];
        }
      } else if (this.dataForm.fillWeek == 1 && this.weekLittleActive != this.thisWeekOptions.length - 1) {
        this.thisSumWeekAndNext = [{
          title: '下周',
          value: '1'
        },{
          title: '下下周',
          value: '2'
        }];
        this.thisSumWeekOptions = this.weekOptions.slice(Number(this.dataForm.fillDay), this.weekOptions.length)
      } else if (this.dataForm.fillWeek == 0 && this.weekLittleActive != this.thisWeekOptions.length - 1) {
        this.thisSumWeekAndNext = [{
          title: '本周',
          value: '0'
        },{
          title: '下周',
          value: '1'
        },{
          title: '下下周',
          value: '2'
        }];
        this.thisSumWeekOptions = this.weekOptions.slice(Number(this.dataForm.fillDay), this.weekOptions.length)
      } else if (this.dataForm.fillWeek == 1 && this.weekLittleActive == this.thisWeekOptions.length - 1) {
        this.thisSumWeekOptions = this.weekOptions;
        this.thisSumWeekAndNext = [{
          title: '下下周',
          value: '2'
        }];
      }

      if (this.thisWeekActive == 0) {
        this.dataForm.fillWeek = this.thisWeekAndNext[0].value;
        this.fillWeekText = this.thisWeekAndNext[0].title;
      }

      this.dataForm.summaryWeek = '';
      this.sumWeekText = '请选择汇总周期';
      this.thisSumWeekActive = 0;
      this.dataForm.summaryDayEqs = '';
      this.sumDayText = '';
      this.weekSumLittleActive = null;

      this.fillWeekShow = false;
    },
    openSumWeek() {
      if (this.dataForm.acquisitionCycle == '') {
        this.$message.warning('请先选择采集时间');
        return;
      }
      if (this.fillWeekText != '' && this.fillDayText != '') {
        this.sumWeekShow = true;
      } else {
        this.$message.warning('请先选择填报周期');
      }
    },
    chooseSumWeek(item, index) {
      this.weekSumLittleActive = index;
      this.dataForm.summaryDayEqs = item.dictKey;
      this.sumWeekShow = false;
      this.sumDayText = '-' + this.thisSumWeekOptions[index].dictValue;
      if (this.thisSumWeekActive == 0) {
        this.dataForm.summaryWeek = this.thisSumWeekAndNext[0].value;
        this.sumWeekText = this.thisSumWeekAndNext[0].title;
      }
    },
    chooseThisSumWeek(item, index) {
      this.thisSumWeekActive = index;
      this.dataForm.summaryWeek = item.value;
      this.sumWeekText = this.thisSumWeekAndNext[index].title;

      this.dataForm.summaryDayEqs = '';
      this.sumDayText = '';
      this.weekSumLittleActive = null;

      if (index == 0 && this.dataForm.fillDay != 7) {
        this.thisSumWeekOptions = this.weekOptions.slice(Number(this.dataForm.fillDay), this.weekOptions.length);
      } else {
        this.thisSumWeekOptions = this.weekOptions;
      }
    },
    // 采集周期为周得采集时间
    chooseCycWeek(e) {
      if (e == 7) {
        this.thisWeekOptions = this.weekOptions;
        this.thisWeekAndNext = [{
          title: '下周',
          value: '1'
        }];
        this.thisSumWeekAndNext = [{
          title: '下周',
          value: '1'
        }, {
          title: '下下周',
          value: '2'
        }];
      } else {
        this.thisWeekOptions = this.weekOptions.slice(e, this.weekOptions.length);
        this.thisWeekAndNext = [{
          title: '本周',
          value: '0'
        }, {
          title: '下周',
          value: '1'
        }];
        this.thisSumWeekAndNext = [{
          title: '本周',
          value: '0'
        }, {
          title: '下周',
          value: '1'
        }, {
          title: '下下周',
          value: '2'
        }];
      }
      this.reastWeek();
    },
    reastWeek() {
      this.thisWeekActive = 0;
      this.weekLittleActive = null;
      this.fillWeekText = '请选择填报截止时间';
      this.fillDayText = '';
      this.thisSumWeekActive = 0;
      this.weekSumLittleActive = null;
      this.sumWeekText = '请选择汇总截止时间';
      this.sumDayText = '';
    },
    // 每年采集时间选择月
    chooseYearMonth(e) {
      dictionary("day_options").then((res) => {
        this.yearDayOption = res.data.data;
        if (e == 2) {
          let year = new Date().getFullYear();
          if (year % 4 == 0 && year % 100 != 0) {
            this.yearDayOption = this.yearDayOption.splice(0, this.yearDayOption.length - 3)
          } else {
            this.yearDayOption = this.yearDayOption.splice(0, this.yearDayOption.length - 4)
          }
        }
        if (e == 4 || e == 6 || e == 9 || e == 11) {
          this.yearDayOption = this.yearDayOption.splice(0, this.yearDayOption.length - 2)
        }
      });
    },
    // 每年采集时间选择日
    chooseYearDay(e) {
      this.reportYearDayOption = this.monthOptions.slice(e, this.monthOptions.length);
      this.sumYearDayOption = this.monthOptions.slice(e, this.monthOptions.length);
    },
    // 每月
    openReportCycMonthPopver() {
      document.addEventListener('click', this.hideReportCycMonthPopover, false)
    },
    closeReportCycMonthPopver() {
      document.removeEventListener('click', this.hideReportCycMonthPopover, false)
    },
    hideReportCycMonthPopover(e) {
      if (!this.$refs.reportCycMonth.$el.contains(e.target)) {
        this.reportMonthShow = false
      }
    },
    openSumCycMonthPopver() {
      document.addEventListener('click', this.hideSumCycMonthPopover, false)
    },
    closeSumCycMonthPopver() {
      document.removeEventListener('click', this.hideSumCycMonthPopover, false)
    },
    hideSumCycMonthPopover(e) {
      if (!this.$refs.sumCycMonth.$el.contains(e.target)) {
        this.sumMonthShow = false
      }
    },
    chooseReportMonthNext(item, index) {
      this.reportMonthNextActive = index;
      this.dataForm.fillWeek = item.value;
      this.reportMonthNextText = this.reportMonthAndNext[index].title;

      this.reportMonthDayActive = null;
      this.dataForm.fillDay = '';
      this.reportMonthDayText = '';

      this.sumMonthNextActive = 0;
      this.dataForm.summaryWeek = '';
      this.sumMonthNextText = '请选择汇总周期';

      this.sumMonthDayActive = null;
      this.dataForm.summaryDayEqs = '';
      this.sumMonthDayText = '';

      if (index == 0 && this.dataForm.acquisitionCycle != 99) {
        this.reportMonthDayOption = this.dayOptions.slice(this.dataForm.acquisitionCycle, this.dayOptions.length);
      } else {
        this.reportMonthDayOption = this.dayOptions;
      }
    },
    chooseReportMonthDay(item, index) {
      this.reportMonthDayActive = index;
      this.dataForm.fillDay = item.dictKey;
      this.reportMonthDayText = '-' + this.reportMonthDayOption[index].dictValue;

      if (this.reportMonthDayActive == this.reportMonthDayOption.length - 1 && this.dataForm.fillWeek == 0) {
        if (this.reportMonthNextActive.length == 1) {
          this.sumMonthDayOption = this.dayOptions;
          this.sumMonthAndNext = [{
            title: '下下月',
            value: '2'
          }];
        } else {
          this.sumMonthDayOption = this.dayOptions;
          this.sumMonthAndNext = [{
            title: '下月',
            value: '1'
          }, {
            title: '下下月',
            value: '2'
          }];
        }
      } else if (this.dataForm.fillWeek == 1 && this.reportMonthDayActive != this.reportMonthDayOption.length - 1) {
        this.sumMonthAndNext = [{
          title: '下月',
          value: '1'
        },{
          title: '下下月',
          value: '2'
        }];
        this.sumMonthDayOption = this.dayOptions.slice(Number(this.dataForm.fillDay), this.dayOptions.length)
      } else if (this.dataForm.fillWeek == 0 && this.reportMonthDayActive != this.reportMonthDayOption.length - 1) {
        this.sumMonthAndNext = [{
          title: '本月',
          value: '0'
        },{
          title: '下月',
          value: '1'
        },{
          title: '下下月',
          value: '2'
        }];
        this.sumMonthDayOption = this.dayOptions.slice(Number(this.dataForm.fillDay), this.dayOptions.length)
      } else if (this.dataForm.fillWeek == 1 && this.reportMonthDayActive == this.reportMonthDayOption.length - 1) {
        this.sumMonthDayOption = this.dayOptions;
        this.sumMonthAndNext = [{
          title: '下下月',
          value: '2'
        }];
      }

      if (this.reportMonthNextActive == 0) {
        this.dataForm.fillWeek = this.reportMonthAndNext[0].value;
        this.reportMonthNextText = this.reportMonthAndNext[0].title;
      }

      this.sumMonthNextActive = 0;
      this.dataForm.summaryWeek = '';
      this.sumMonthDayActive = null;
      this.dataForm.summaryDayEqs = '';
      this.sumMonthNextText = '请选择汇总周期';
      this.sumMonthDayText = '';

      this.reportMonthShow = false;
    },
    chooseSumMonthNext(item, index) {
      this.sumMonthNextActive = index;
      this.dataForm.summaryWeek = item.value;
      this.sumMonthNextText = this.sumMonthAndNext[index].title;

      this.sumMonthDayActive = null;
      this.dataForm.summaryDayEqs = '';
      this.sumMonthDayText = '';

      if (index == 0 && this.dataForm.fillDay != 99) {
        this.sumMonthDayOption = this.dayOptions.slice(Number(this.dataForm.fillDay), this.dayOptions.length);
      } else {
        this.sumMonthDayOption = this.dayOptions;
      }
    },
    chooseSumMonthDay(item, index) {
      this.sumMonthDayActive = index;
      this.dataForm.summaryDayEqs = item.dictKey;
      this.sumMonthShow = false;
      this.sumMonthDayText = '-' + this.sumMonthDayOption[index].dictValue;
      if (this.sumMonthNextActive == 0) {
        this.dataForm.summaryWeek = this.sumMonthAndNext[0].value;
        this.sumMonthNextText = this.sumMonthAndNext[0].title;
      }
    },
    openReportMonth() {
      if (this.dataForm.acquisitionCycle == '') {
        this.$message.warning('请先选择采集时间');
        return;
      }
      this.reportMonthShow = true;
    },
    openSumMonth() {
      if (this.dataForm.acquisitionCycle == '') {
        this.$message.warning('请先选择采集时间');
        return;
      }
      if (this.reportMonthNextText != '' && this.reportMonthDayText != '') {
        this.sumMonthShow = true;
      } else {
        this.$message.warning('请先选择填报周期');
      }
    },
    chooseCycMonth(e) {
      if (e == '99') {
        this.reportMonthDayOption = this.dayOptions;
        this.reportMonthAndNext = [{
          title: '下月',
          value: '1'
        }];
      } else {
        this.reportMonthDayOption = this.dayOptions.slice(e, this.dayOptions.length);
        this.reportMonthAndNext = [{
          title: '本月',
          value: '0'
        }, {
          title: '下月',
          value: '1'
        }];
      }
      this.reastMonth();
    },
    reastMonth() {
      this.reportMonthNextActive = 0;
      this.reportMonthDayActive = null;
      this.reportMonthNextText = '请选择填报截止时间';
      this.reportMonthDayText = '';
      this.sumMonthNextActive = 0;
      this.sumMonthDayOption = null;
      this.sumMonthNextText = '请选择汇总截止时间';
      this.sumMonthDayText = '';
    },
    // 每年
    openReportCycYearPopver() {
      document.addEventListener('click', this.hideReportCycYearPopover, false)
    },
    closeReportCycYearPopver() {
      document.removeEventListener('click', this.hideReportCycYearPopover, false)
    },
    hideReportCycYearPopover(e) {
      if (!this.$refs.reportCycYear.$el.contains(e.target)) {
        this.reportYearShow = false
      }
    },
    openSumCycYearPopver() {
      document.addEventListener('click', this.hideSumCycYearPopover, false)
    },
    closeSumCycYearPopver() {
      document.removeEventListener('click', this.hideSumCycYearPopover, false)
    },
    hideSumCycYearPopover(e) {
      if (!this.$refs.sumCycYear.$el.contains(e.target)) {
        this.sumYearShow = false
      }
    },
    chooseReportYearNext(item, index) {
      this.reportYearNextActive = index;
      this.dataForm.fillWeek = item.value;
      this.reportYearNextText = this.reportYearAndNext[index].title;

      if (this.reportYearAndNext.length == 2) {
        if (this.dataForm.fillWeek == 0) {
          if (this.month != '12' && this.day != this.yearDayOption.length) {
            this.reportYearMonthOption = this.monthOptions.slice(Number(this.month) - 1,this.monthOptions.length);
            this.reportYearDayOption = this.yearDayOption.slice(Number(this.day),this.yearDayOption.length);
          } else if (this.month != '12' && this.day == this.yearDayOption.length) {
            this.reportYearMonthOption = this.monthOptions.slice(Number(this.month) ,this.monthOptions.length);
            this.reportYearDayOption = this.yearDayOption;
          }
        } else {
          this.reportYearMonthOption = this.monthOptions;
          this.reportYearDayOption = this.yearDayOption;
        }
      } else {
        if (this.month != '12' && this.day != this.yearDayOption.length) {
          this.reportYearMonthOption = this.monthOptions.slice(Number(this.month) - 1,this.monthOptions.length);
          this.reportYearDayOption = this.yearDayOption.slice(Number(this.day),this.yearDayOption.length);
        } else if (this.month != '12' && this.day == this.yearDayOption.length) {
          this.reportYearMonthOption = this.monthOptions.slice(Number(this.month) ,this.monthOptions.length);
          this.reportYearDayOption = this.yearDayOption;
        }
      }

      this.reportYearMonthActive = 0;
      this.dataForm.fillMonth = '';
      this.reportYearMonthText = '';

      this.reportYearDayActive = null;
      this.dataForm.fillDay = '';
      this.reportYearDayText = '';
    },
    chooseReportYearMonth(item, index) {
      this.reportYearMonthActive = index;
      this.dataForm.fillMonth = item.dictKey;
      this.reportYearMonthText = '-' + this.reportYearMonthOption[index].dictValue;

      this.reportYearDayActive = null;
      this.dataForm.fillDay = '';
      this.reportYearDayText = '';

      if (this.reportYearNextActive == 0) {
        this.dataForm.fillWeek = this.reportYearAndNext[0].value;
        this.reportYearNextText = this.reportYearAndNext[0].title;
      }
      if (this.reportYearAndNext.length == 2) {
        if (this.dataForm.fillWeek == 0) {
          if (index == 0) {
            this.reportYearDayOption = this.yearDayOption.slice(Number(this.day),this.yearDayOption.length - 1);
          } else {
            this.reportYearDay();
          }
        } else {
          this.reportYearDay();
        }
      } else {
        if (index == 0) {
          this.reportYearDayOption = this.yearDayOption.slice(Number(this.day),this.yearDayOption.length);
        } else {
          this.reportYearDay();
        }
      }
    },
    reportYearDay() {
      if (this.dataForm.fillMonth == 2) {
        let year = new Date().getFullYear();
        if (year % 4 == 0 && year % 100 != 0) {
          this.reportYearDayOption = this.yearDayOption.slice(0, this.yearDayOption.length - 2)
        } else {
          this.reportYearDayOption = this.yearDayOption.slice(0, this.yearDayOption.length - 3)
        }
      } else if (this.dataForm.fillMonth == 4 || this.dataForm.fillMonth == 6 || this.dataForm.fillMonth == 9 || this.dataForm.fillMonth == 11) {
        this.reportYearDayOption = this.yearDayOption.slice(0, this.yearDayOption.length - 2)
      } else {
        this.reportYearDayOption = this.yearDayOption.slice(0, this.yearDayOption.length - 1)
      }
    },
    chooseReportYearDay(item, index) {
      this.reportYearDayActive = index;
      this.dataForm.fillDay = item.dictKey;
      this.reportYearShow = false;
      this.reportYearDayText = '-' + this.reportYearDayOption[index].dictValue;

      if (this.reportYearNextActive == 0) {
        this.dataForm.fillWeek = this.reportYearAndNext[0].value;
        this.reportYearNextText = this.reportYearAndNext[0].title;
      }

      if (this.reportYearMonthActive == 0) {
        this.dataForm.fillMonth = this.reportYearMonthOption[0].dictKey;
        this.reportYearMonthText = '-' + this.reportYearMonthOption[0].dictValue;
      }

      this.sumYearNextActive = 0;
      this.dataForm.summaryWeek = '';
      this.sumYearMonthActive = 0;
      this.dataForm.summaryMonth = '';
      this.sumYearDayActive = null;
      this.dataForm.summaryDayEqs = '';
      this.sumYearNextText = '请选择汇总周期';
      this.sumYearMonthText = '';
      this.sumYearDayText = '';

      if (this.dataForm.fillWeek == 0) {
        if (this.dataForm.fillMonth != '12' && this.reportYearDayActive != this.reportYearDayOption.length - 1) {
          this.sumYearAndNext = [{
            title:'本年',
            value:'0'
          },{
            title:'下年',
            value:'1'
          },{
            title:'下下年',
            value:'2'
          }]
          this.sumYearMonthOption = this.monthOptions.slice(Number(this.dataForm.fillMonth) - 1,this.monthOptions.length)
          this.sumYearDayOption = this.yearDayOption.slice(Number(this.dataForm.fillDay),this.yearDayOption.length - 1)
        } else if (this.dataForm.fillMonth != '12' && this.reportYearDayActive == this.reportYearDayOption.length - 1) {
          this.sumYearAndNext = [{
            title:'本年',
            value:'0'
          },{
            title:'下年',
            value:'1'
          },{
            title:'下下年',
            value:'2'
          }]
          this.sumYearMonthOption = this.monthOptions.slice(Number(this.dataForm.fillMonth),this.monthOptions.length - 1)
          this.sumYearDayOption = this.yearDayOption;
        } else if (this.dataForm.fillMonth == '12' && this.reportYearDayActive != this.reportYearDayOption.length - 1) {
          this.sumYearAndNext = [{
            title:'本年',
            value:'0'
          },{
            title:'下年',
            value:'1'
          },{
            title:'下下年',
            value:'2'
          }]
          this.sumYearMonthOption = this.monthOptions.slice(Number(this.dataForm.fillMonth) - 1,this.monthOptions.length)
          this.sumYearDayOption = this.yearDayOption.slice(Number(this.dataForm.fillDay),this.yearDayOption.length - 1)
        } else if (this.dataForm.fillMonth == '12' && this.reportYearDayActive == this.reportYearDayOption.length - 1) {
          this.sumYearAndNext = [{
            title:'下年',
            value:'1'
          },{
            title:'下下年',
            value:'2'
          }]
          this.sumYearMonthOption = this.monthOptions;
          this.sumYearDayOption = this.yearDayOption;
        }
      } else {
        if (this.dataForm.fillMonth != '12' && this.reportYearDayActive != this.reportYearDayOption.length - 1) {
          this.sumYearAndNext = [{
            title:'下年',
            value:'1'
          },{
            title:'下下年',
            value:'2'
          }]
          this.sumYearMonthOption = this.monthOptions.slice(Number(this.dataForm.fillMonth) - 1,this.monthOptions.length - 1)
          this.sumYearDayOption = this.yearDayOption.slice(Number(this.dataForm.fillDay),this.yearDayOption.length - 1)
        } else if (this.dataForm.fillMonth != '12' && this.reportYearDayActive == this.reportYearDayOption.length - 1) {
          this.sumYearAndNext = [{
            title:'下年',
            value:'1'
          },{
            title:'下下年',
            value:'2'
          }]
          this.sumYearMonthOption = this.monthOptions.slice(Number(this.dataForm.fillMonth),this.monthOptions.length - 1)
          this.sumYearDayOption = this.yearDayOption;
        } else if (this.dataForm.fillMonth == '12' && this.reportYearDayActive != this.reportYearDayOption.length - 1) {
          this.sumYearAndNext = [{
            title:'下年',
            value:'1'
          },{
            title:'下下年',
            value:'2'
          }]
          this.sumYearMonthOption = this.monthOptions.slice(Number(this.dataForm.fillMonth) - 1,this.monthOptions.length)
          this.sumYearDayOption = this.yearDayOption.slice(Number(this.dataForm.fillDay),this.yearDayOption.length - 1)
        } else if (this.dataForm.fillMonth == '12' && this.reportYearDayActive == this.reportYearDayOption.length - 1) {
          this.sumYearAndNext = [{
            title:'下下年',
            value:'2'
          }]
          this.sumYearMonthOption = this.monthOptions;
          this.sumYearDayOption = this.yearDayOption;
        }
      }
    },
    chooseSumYearNext(item, index) {
      this.sumYearNextActive = index;
      this.dataForm.summaryWeek = item.value;
      this.sumYearNextText = this.sumYearAndNext[index].title;

      this.sumYearMonthActive = 0;
      this.dataForm.summaryMonth = '';
      this.sumYearMonthText = '';

      this.sumYearDayActive = null;
      this.dataForm.summaryDayEqs = '';
      this.sumYearDayText = '';

      if (this.sumYearNextActive == 0) {
        if (this.dataForm.fillMonth != '12' && this.reportYearDayActive != this.reportYearDayOption.length - 1) {
          this.sumYearMonthOption = this.monthOptions.slice(Number(this.dataForm.fillMonth) - 1,this.monthOptions.length)
          this.sumYearDayOption = this.yearDayOption.slice(Number(this.dataForm.fillDay),this.yearDayOption.length - 2)
        }else if (this.dataForm.fillMonth != '12' && this.reportYearDayActive == this.reportYearDayOption.length - 1) {
          this.sumYearMonthOption = this.monthOptions.slice(Number(this.dataForm.fillMonth),this.monthOptions.length - 1)
          this.sumYearDay();
        } else if (this.dataForm.fillMonth == '12' && this.reportYearDayActive != this.reportYearDayOption.length - 1) {
          this.sumYearMonthOption = this.monthOptions.slice(Number(this.dataForm.fillMonth) - 1,this.monthOptions.length)
          this.sumYearDayOption = this.yearDayOption.slice(Number(this.dataForm.fillDay),this.yearDayOption.length - 2)
        } else if (this.dataForm.fillMonth == '12' && this.reportYearDayActive == this.reportYearDayOption.length - 1) {
          this.sumYearMonthOption = this.monthOptions;
          this.sumYearDay();
        }
      } else {
        this.sumYearMonthOption = this.monthOptions;
        this.sumYearDay();
      }
    },
    chooseSumYearMonth(item, index) {
      this.sumYearMonthActive = index;
      this.sumYearMonthText = '-' + item.dictValue;
      this.dataForm.summaryMonth = item.dictKey;

      this.sumYearDayActive = null;
      this.dataForm.summaryDayEqs = '';
      this.sumYearDayText = '';

      if (this.sumYearNextActive == 0) {
        this.dataForm.summaryWeek = this.sumYearAndNext[0].value;
        this.sumYearNextText = this.sumYearAndNext[0].title;
      }

      if (this.sumYearAndNext.length == 3) {
        if (this.dataForm.summaryWeek == 0) {
          if (index == 0) {
            this.sumYearDayOption = this.yearDayOption.slice(Number(this.dataForm.fillDay),this.yearDayOption.length);
          } else {
            this.sumYearDay();
          }
        } else {
          this.sumYearDay();
        }
      } else {
        if (index == 0) {
          this.sumYearDayOption = this.yearDayOption.slice(Number(this.dataForm.fillDay),this.yearDayOption.length);
        } else {
          this.sumYearDay();
        }
      }
    },
    sumYearDay() {
      if (this.dataForm.summaryMonth == 2) {
        let year = new Date().getFullYear();
        if (year % 4 == 0 && year % 100 != 0) {
          this.sumYearDayOption = this.mainDayOptions.slice(0, this.mainDayOptions.length - 3)
        } else {
          this.sumYearDayOption = this.mainDayOptions.slice(0, this.mainDayOptions.length - 4)
        }
      } else if (this.dataForm.summaryMonth == 4 || this.dataForm.summaryMonth == 6 || this.dataForm.summaryMonth == 9 || this.dataForm.summaryMonth == 11) {
        this.sumYearDayOption = this.mainDayOptions.slice(0, this.mainDayOptions.length - 2)
      } else {
        this.sumYearDayOption = this.mainDayOptions.slice(0, this.mainDayOptions.length - 1)
      }
    },
    chooseSumYearDay(item, index) {
      // if (Number(this.dataForm.fillWeek) > Number(this.dataForm.summaryWeek)) {
      //   this.$message.warning('汇总日期必须大于填报日期');
      //   return;
      // }
      // if (Number(this.reportYearNextActive) == Number(this.sumYearNextActive) && Number(this.reportYearDayActive) >= index) {
      //   this.$message.warning('汇总日期必须大于填报日期');
      //   return;
      // }
      this.sumYearDayActive = index;
      this.dataForm.summaryDayEqs = item.dictKey;
      this.sumYearShow = false;
      this.sumYearDayText = '-' + this.sumYearDayOption[index].dictValue;
      if (this.sumYearNextActive == 0) {
        this.dataForm.summaryWeek = this.sumYearAndNext[0].value;
        this.sumYearNextText = this.sumYearAndNext[0].title;
      }
      if (this.sumYearMonthActive == 0) {
        this.dataForm.summaryMonth = '1';
        this.sumYearMonthText = '-' + this.sumYearMonthOption[0].dictValue;
      }
    },
    // 打开填报周期年
    openReportYear() {
      if (this.month != '' && this.day != '') {
        if (this.month != '12' && this.day != this.yearDayOption.length) {
          this.reportYearAndNext = [{
            title:'本年',
            value:'0'
          },{
            title:'下年',
            value:'1'
          }]
          this.reportYearMonthOption = this.monthOptions.slice(Number(this.month) - 1,this.monthOptions.length);
          this.reportYearDayOption = this.yearDayOption.slice(Number(this.day),this.yearDayOption.length - 1);
        } else if (this.month != '12' && this.day == this.yearDayOption.length) {
          this.reportYearAndNext = [{
            title:'本年',
            value:'0'
          },{
            title:'下年',
            value:'1'
          }]
          this.reportYearMonthOption = this.monthOptions.slice(Number(this.month) ,this.monthOptions.length);
          this.reportYearDayOption = this.yearDayOption;
        } else if (this.month == '12' && this.day == this.yearDayOption.length) {
          this.reportYearAndNext = [{
            title:'下年',
            value:'1'
          }]
          this.reportYearMonthOption = this.monthOptions;
          this.reportYearDayOption = this.yearDayOption;
        } else if (this.month == '12' && this.day != this.yearDayOption.length) {
          this.reportYearAndNext = [{
            title:'本年',
            value:'0'
          },{
            title:'下年',
            value:'1'
          }]
          this.reportYearMonthOption = this.monthOptions.slice(Number(this.month) - 1,this.monthOptions.length);
          this.reportYearDayOption = this.yearDayOption.slice(Number(this.day),this.yearDayOption.length - 1);
        }
        this.reportYearShow = true;
      } else {
        this.$message.warning('请先选择采集时间');
      }
    },
    // 打开汇总周期年
    openSumYear() {
      if (this.month != '' && this.day != '') {
        if (this.reportYearNextText != '' && this.reportYearMonthText != '' && this.reportYearDayText != '') {

          this.sumYearShow = true;
        } else {
          this.$message.warning('请先选择填报周期');
        }
      } else {
        this.$message.warning('请先选择采集时间');
      }
    },
    handleDateChange() {
      if (this.dataForm.deadlineFillingDate && this.dataForm.deadlineSummaryDate) {
        const fillingDate = new Date(this.dataForm.deadlineFillingDate);
        const summaryDate = new Date(this.dataForm.deadlineSummaryDate);

        if (summaryDate <= fillingDate) {
          // 汇总时间早于或等于填报时间，进行处理逻辑
          // 弹出提示信息并且置空截至汇总时间
          this.$message.error('汇总截至日期必须晚于填报截至日期');
          this.dataForm.deadlineSummaryDate = null;
        }
      }
    },
    // 获取填报周期时间
    calculateDays() {
      this.summaryDay = null;
      if (this.reportingDay) {
        const start = new Date(this.reportingDay[0]);
        const end = new Date(this.reportingDay[1]);

        // 将日期转换为时间戳再进行计算，以避免时区问题
        const startTimeStamp = start.getTime();
        const endTimeStamp = end.getTime();
        this.dataForm.reportingPeriod = Math.abs(Math.floor((endTimeStamp - startTimeStamp) / (1000 * 60 * 60 * 24))) + 1; // 计算天数差
        if (end != null) {
          this.reportingByAfter = end;
        }
      } else {
        this.dataForm.reportingPeriod = 0; // 如果未选择日期，重置天数差为0
      }
    },
    // 填报周期时间校验
    validateReportingPeriod(rule, value, callback) {
      if (this.reportingDay) {
        callback(); // 表名有效，调用回调函数继续表单验证。
      } else {
        callback(new Error('请选择填报截止时间')); // 表名无效，调用回调函数并传入错误信息。
      }
    },
    // 获取汇总周期时间
    calculateSummaryDays() {
      if (this.summaryDay) {
        const start = new Date(this.summaryDay[0]);
        const end = new Date(this.summaryDay[1]);

        // 将日期转换为时间戳再进行计算，以避免时区问题
        const startTimeStamp = start.getTime();
        const endTimeStamp = end.getTime();
        this.dataForm.summaryPeriod = Math.abs(Math.floor((endTimeStamp - startTimeStamp) / (1000 * 60 * 60 * 24))) + 1; // 计算天数差
      } else {
        this.dataForm.summaryPeriod = 0; // 如果未选择日期，重置天数差为0
      }
    },
    // 填报汇总时间校验
    validateSummaryPeriod(rule, value, callback) {
      if (this.summaryDay) {
        callback(); // 表名有效，调用回调函数继续表单验证。
      } else {
        callback(new Error('请选择汇总周期')); // 表名无效，调用回调函数并传入错误信息。
      }
    },
    // 获取组织架构
    getDeptChildTreeData(data) {
      mergeDeptUser(this.dataForm.roleId).then((res) => {
        if (res.data.code == 200) {
          this.deptTreeShow = true;
          let list = new Array();
          list = res.data.data;
          this.recursion(list);
          this.deptChildTreeData = list;
          this.fndeptChildTreeData = this.fnTree(this.deptChildTreeData);
          this.dataForm.roleId = this.dataForm.roleId.split(',');
          // this.reportingDay = this.dataForm.reportingDay.split(':');
          // this.summaryDay = this.dataForm.summaryDay.split(':');
          this.dataForm.acquisitionStatus = data.data.data.acquisitionAttribute.acquisitionStatus.toString();
          if (data.data.data.acquisitionAttribute.attachDescription != '') {
            this.fileList = JSON.parse(data.data.data.acquisitionAttribute.attachDescription)
          }
          if (this.dataForm.acquisitionCycleType == 4) {
            let timeData = this.dataForm.acquisitionCycle.split(',');
            this.month = timeData[0];
            this.day = timeData[1];
          }
          this.rightShutData = data.data.data.acquisitionAttribute.summaryList;
          let middleShutData = data.data.data.acquisitionAttribute.fillList;
          setTimeout(() => {
            let list = new Array();
            middleShutData.forEach((item) => {
              let data = this.fndeptChildTreeData.filter((i) => {
                return item.unitId == i.id
              })
              this.middleShutData.push(data[0]);
              list.push(data[0].id)
              this.defaultCheckedKeys = list;
            })
          }, 1000)
        }
      })
    },
    // 点击选择组织
    handleCheckChange(node, nodeKey) {
      node.checked = nodeKey;
      // if (node.children && node.children.length > 0) {
      //   node.children.forEach(child => {
      //     this.$refs.tree.setChecked(child, node.checked);
      //   });
      // }
      this.middleShutData = this.$refs.tree.getCheckedNodes();
      this.countUnit();
    },
    recursion(arr) {
      arr.forEach((item) => {
        // if(item.parentId == 0) this.ids = ''
        let name = new Array();
        let tmp = this.ids + "," + item.id;
        // let tmpName = this.names + "/" + item.fullName;
        let tmpNames = this.namesM + "," + item.fullName;
        item.organizeIds = tmp.slice(1).split(",");
        // item.organize = tmpName.slice(1);
        item.unitId = item.id;
        if (item.userList.length > 0) {
          item.userList.forEach((i) => {
            name.push(i.realName)
          })
        }
        item.organizeName = tmpNames.slice(1).split(",");
        item.organize = item.organizeName.join('/');
        item.fullName = item.fullName;
        item.userNameList = name.join(',');
        if (item.children) {
          this.ids += "," + item.id;
          this.names += "/" + item.fullName;
          this.namesM += "," + item.fullName;
          this.recursion(item.children);
          this.ids = this.ids.slice(0, this.ids.lastIndexOf(","));
          this.names = this.names.slice(0, this.names.lastIndexOf("/"));
          this.namesM = this.namesM.slice(0, this.namesM.lastIndexOf(","));
        }
      });
    },
    //选择组织
    chooseUnit(index) {
      this.unitActive = index;
    },
    // 选择每天清空采集时间
    chooseCycle(e) {
      this.dataForm.acquisitionCycle = '';
      this.month = '';
      this.day = '';
      this.dataForm.deadlineFillingDate = '';
      this.dataForm.deadlineSummaryDate = '';

      this.dataForm.fillMonth = '';
      this.dataForm.fillWeek = '';
      this.dataForm.fillDay = '';
      this.dataForm.summaryWeek = '';
      this.dataForm.summaryDayEqs = '';
      this.dataForm.summaryMonth = '';

      this.thisWeekActive = 0;
      this.weekLittleActive = null;
      this.thisSumWeekActive = 0;
      this.weekSumLittleActive = null;

      this.fillWeekText = '请选择填报截止时间';
      this.fillDayText = '';
      this.sumWeekText = '请选择汇总截止时间';
      this.sumDayText = '';

      this.reportMonthNextActive = 0;
      this.reportMonthDayActive = null;
      this.sumMonthNextActive = 0;
      this.sumMonthDayActive = null;

      this.reportMonthNextText = '请选择填报截止时间';
      this.reportMonthDayText = '';
      this.sumMonthNextText = '请选择汇总截止时间';
      this.sumMonthDayText = '';

      this.reportYearNextActive = 0;
      this.reportYearMonthActive = 0;
      this.reportYearDayActive = null;
      this.sumYearNextActive = 0;
      this.sumYearMonthActive = 0;
      this.sumYearDayActive = null;

      this.reportYearNextText = '请选择填报截止时间';
      this.reportYearMonthText = '';
      this.reportYearDayText = '';
      this.sumYearNextText = '请选择汇总截止时间';
      this.sumYearMonthText = '';
      this.sumYearDayText = '';

      if (e == 5) {
        this.dataForm.isCycle = '2';
      } else if (e == 3) {
        this.dataForm.isCycle = '1';
        dictionary("day_options").then((res) => {
          this.dayOptions = res.data.data;
        });
      } else {
        this.dataForm.isCycle = '1';
      }
    },
    // 选择常用分组
    chooseGroup(e) {
      this.middleShutData = [];
      this.rightShutData = [];
      let groupIds = new Array();
      this.groupOption.forEach((item) => {
        e.forEach((items) => {
          if (items == item.groupName) {
            groupIds.push(item.id)
          }
        })
      })
      let data = new Array();
      let middleDataList = new Array();
      groupIds.forEach((item) => {
        DeptGroupDetail({id: item}).then((res) => {
          data = data.concat(res.data.data.deptIdList);
        })
      })
      setTimeout(() => {
        data.forEach((item) => {
          let data = this.fndeptChildTreeData.filter((i) => {
            return i.id == item
          })
          middleDataList.push(data[0])
        })
        this.middleShutData = middleDataList;
        this.countUnit();
      }, 1000)
    },
    chooseTemplateClassify() {
      this.dataForm.templateCode = '';
    },
    // 选择模板编码带出表名称
    chooseTemplate(e) {
      if (this.dataForm.templateClassify == 1) {
        let data = this.templateOptions.filter((item) => {
          return e == item.templateCode
        })
        this.dataForm.templateName = data[0].templateName;
        this.dataForm.title = data[0].templateName;
        this.dataForm.taskName = data[0].templateName;
      }
      if (this.dataForm.templateClassify == 2) {
        let data = this.reportTemplateOption.filter((item) => {
          return e == item.id;
        })
        this.dataForm.templateName = data[0].reportName;
        this.dataForm.title = data[0].reportName;
        this.dataForm.taskName = data[0].reportName;
      }
    },
    getDictory() {
      dictionaryBiz("report_data_type").then((res) => {
        this.typeOptions = res.data.data;
      });
      dictionary("yes_no").then((res) => {
        this.yesOrNoOptions = res.data.data;
      });
      dictionaryBiz("report_input_way").then((res) => {
        this.inputWayOptions = res.data.data;
      });
      dictionaryBiz("publish_status").then((res) => {
        this.publishStatusOption = res.data.data;
      });
      dictionaryBiz("acquisition_cycle").then((res) => {
        this.acquisitionCycleOption = res.data.data;
      });
      dictionaryBiz("templateClassify").then((res) => {
        this.templateClassifyOption = res.data.data;
      });
      GettrainingDeptGroup({
        current: 1,
        size: 50,
      }).then((res) => {
        this.groupOption = res.data.data.records;
      })
      dictionary("week_options").then((res) => {
        this.weekOptions = res.data.data;
      });
      dictionary("day_options").then((res) => {
        this.dayOptions = res.data.data;
        this.mainDayOptions = res.data.data;
        this.yearDayOption = res.data.data.slice(0, res.data.data.length - 1);
        this.reportYearDayOption = res.data.data.slice(0, res.data.data.length - 1);
        this.sumYearDayOption = res.data.data.slice(0, res.data.data.length - 1);
      });
      dictionary("month_options").then((res) => {
        this.monthOptions = res.data.data;
        this.reportYearMonthOption = res.data.data;
        this.sumYearMonthOption = res.data.data;
      });
      reportList({
        reportType: '1003',
        deptId: this.userInfo.dept_id
      }).then((res) => {
        if (res.data.code == 200) {
          this.reportTemplateOption = res.data.data;
        }
      })
    },
    // 获取当前登录用户信息
    getNowUserInfo() {
      getUserInfo().then((res) => {
        this.dataForm.weaveUser = JSON.parse(res.data.data.realName).zh;
        this.dataForm.weaveDept = res.data.data.deptName;
        //赋值默认角色
        // this.dataForm.roleId = this.userInfo.role_id.split(",");
        this.chooseRole(this.dataForm.roleId);
        templateByEnable(res.data.data.deptId).then((res) => {
          this.templateOptions = res.data.data
        })
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    // 保存填报设置
    headSave(boolean) {
      if (this.middleShutData.length == 0) {
        this.$message({
          message: "请选择填报单位",
          type: "warning",
        });
        return;
      }
      if (this.dataForm.acquisitionCycleType == 4) {
        if (this.month == '') {
          this.$message({
            message: "请选择月份",
            type: "warning",
          });
          return;
        }
        if (this.day == '') {
          this.$message({
            message: "请选择日期",
            type: "warning",
          });
          return;
        }
      }
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          this.$loading();
          this.dataForm.roleId = this.dataForm.roleId.toString();
          this.dataForm.fillList = this.middleShutData;
          this.dataForm.summaryList = this.rightShutData;
          if (this.dataForm.acquisitionCycleType == 4) {
            this.dataForm.acquisitionCycle = this.month + ',' + this.day;
          }
          // if(this.dataForm.acquisitionCycleType != 5){
          //   this.dataForm.summaryDay = this.summaryDay[0] + ":" + this.summaryDay[1];
          //   this.dataForm.reportingDay = this.reportingDay[0] + ":" + this.reportingDay[1];
          // }
          attributeSave(this.dataForm).then((res) => {
            if (res.data.code == 200) {
              if (boolean) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                this.dataForm = res.data.data.acquisitionAttribute;
                this.dataForm.roleId = this.dataForm.roleId.split(',');
                this.dataForm.acquisitionStatus = this.dataForm.acquisitionStatus.toString();
                this.rightShutData = res.data.data.acquisitionAttribute.summaryList;
                // this.reportingDay = this.dataForm.reportingDay.split(':');
                // this.summaryDay = this.dataForm.summaryDay.split(':');
                let middleData = new Array();
                let middleShutData = res.data.data.acquisitionAttribute.fillList;
                middleShutData.forEach((item) => {
                  let data = this.fndeptChildTreeData.filter((i) => {
                    return item.unitId == i.id
                  })
                  middleData.push(data[0]);
                })
                this.middleShutData = middleData;
              }
              this.$message({
                message: "保存成功",
                type: "success",
              });
              this.$loading().close();
            }
          })
        }
      });
    },
    // 根据ID查表单详情
    getAttributeDetail() {
      attributeDetail(this.dataForm.id).then((res) => {
        if (res.data.code == 200) {
          this.dataForm = res.data.data.acquisitionAttribute;
          // 每周
          if (this.dataForm.acquisitionCycleType == '2') {
            if (this.dataForm.acquisitionCycle == 7) {
              this.thisWeekAndNext = [{
                title:'下周',
                value:'1'
              }]
            } else {
              this.thisWeekAndNext = [{
                title:'本周',
                value:'0'
              },{
                title:'下周',
                value:'1'
              }]
            }

            if (this.dataForm.fillWeek == 0 && this.dataForm.fillDay != 7) {
              this.thisSumWeekAndNext = [{
                title:'本周',
                value:'0'
              },{
                title:'下周',
                value:'1'
              },{
                title:'下下周',
                value:'2'
              }]
            } else if (this.dataForm.fillWeek == 0 && this.dataForm.fillDay == 7) {
              this.thisSumWeekAndNext = [{
                title:'下周',
                value:'1'
              },{
                title:'下下周',
                value:'2'
              }]
            } else if (this.dataForm.fillWeek == 1 && this.dataForm.fillDay == 7) {
              this.thisSumWeekAndNext = [{
                title:'下下周',
                value:'2'
              }]
            } else if (this.dataForm.fillWeek == 1 && this.dataForm.fillDay != 7) {
              this.thisSumWeekAndNext = [{
                title:'下周',
                value:'1'
              },{
                title:'下下周',
                value:'2'
              }]
            }

            if (this.dataForm.fillWeek == 0) {
              this.thisWeekOptions = this.weekOptions.slice(this.dataForm.acquisitionCycle, this.weekOptions.length);
            } else {
              this.thisWeekOptions = this.weekOptions
            }
            if (this.dataForm.summaryWeek == 0) {
              this.thisSumWeekOptions = this.weekOptions.slice(Number(this.dataForm.fillDay), this.weekOptions.length);
            } else {
              this.thisSumWeekOptions = this.weekOptions
            }
            // 回显填报周期
            this.thisWeekOptions.forEach((item, index) => {
              if (item.dictKey == this.dataForm.fillDay) {
                this.weekLittleActive = index;
                this.fillDayText = '-' + this.thisWeekOptions[index].dictValue;
              }
            })
            //

            if (this.dataForm.fillWeek == '0') {
              this.fillWeekText = '本周'
            } else {
              this.fillWeekText = '下周'
            }

            if (this.thisWeekAndNext.length == 1) {
              this.thisWeekActive = 0;
            } else if (this.thisWeekAndNext.length == 2) {
              this.thisWeekActive = this.dataForm.fillWeek
            }

            // 回显汇总周期
            this.thisSumWeekOptions.forEach((item, index) => {
              if (item.dictKey == this.dataForm.summaryDayEqs) {
                this.weekSumLittleActive = index;
                this.sumDayText = '-' + this.thisSumWeekOptions[index].dictValue;
              }
            })
            if (this.dataForm.summaryWeek == 0) {
              this.sumWeekText= '本周'
            } else if (this.dataForm.summaryWeek == 1) {
              this.sumWeekText = '下周'
            } else {
              this.sumWeekText = '下下周'
            }

            if (this.thisSumWeekAndNext.length == 1) {
              this.thisSumWeekActive = 0;
            } else {
              this.thisSumWeekActive = this.dataForm.summaryWeek
            }
          }
          // 每月
          if (this.dataForm.acquisitionCycleType == '3') {
            if (this.dataForm.acquisitionCycle == 99) {
              this.reportMonthAndNext = [{
                title:'下月',
                value:'1'
              }]
            } else {
              this.reportMonthAndNext = [{
                title:'本月',
                value:'0'
              },{
                title:'下月',
                value:'1'
              }]
            }

            if (this.dataForm.fillWeek == 0 && this.dataForm.fillDay != 99) {
              this.sumMonthAndNext = [{
                title:'本月',
                value:'0'
              },{
                title:'下月',
                value:'1'
              },{
                title:'下下月',
                value:'2'
              }]
            } else if (this.dataForm.fillWeek == 0 && this.dataForm.fillDay == 99) {
              this.sumMonthAndNext = [{
                title:'下月',
                value:'1'
              },{
                title:'下下月',
                value:'2'
              }]
            } else if (this.dataForm.fillWeek == 1 && this.dataForm.fillDay == 99) {
              this.sumMonthAndNext = [{
                title:'下下月',
                value:'2'
              }]
            } else if (this.dataForm.fillWeek == 1 && this.dataForm.fillDay != 99) {
              this.sumMonthAndNext = [{
                title:'下月',
                value:'1'
              },{
                title:'下下月',
                value:'2'
              }]
            }

            if (this.dataForm.fillWeek == 0) {
              this.reportMonthDayOption = this.dayOptions.slice(this.dataForm.acquisitionCycle, this.dayOptions.length);
            } else {
              this.reportMonthDayOption = this.dayOptions
            }
            if (this.dataForm.summaryWeek == 0) {
              this.sumMonthDayOption = this.dayOptions.slice(Number(this.dataForm.acquisitionCycle), this.dayOptions.length);
            } else {
              this.sumMonthDayOption = this.dayOptions
            }

            if (this.dataForm.fillWeek == '0') {
              this.reportMonthNextText = '本月'
            } else {
              this.reportMonthNextText = '下月'
            }

            if (this.reportMonthAndNext.length == 1) {
              this.reportMonthNextActive = 0;
            } else if (this.reportMonthAndNext.length == 2) {
              this.reportMonthNextActive = this.dataForm.fillWeek
            }

            // 回显填报周期
            this.reportMonthDayOption.forEach((item, index) => {
              if (item.dictKey == this.dataForm.fillDay) {
                this.reportMonthDayActive = index;
                this.reportMonthDayText = '-' + this.reportMonthDayOption[index].dictValue;
              }
            })
            // 回显汇总周期
            this.sumMonthDayOption.forEach((item, index) => {
              if (item.dictKey == this.dataForm.summaryDayEqs) {
                this.sumMonthDayActive = index;
                this.sumMonthDayText = '-' + this.sumMonthDayOption[index].dictValue;
              }
            })

            if (this.dataForm.summaryWeek == 0) {
              this.sumMonthNextText= '本月'
            } else if (this.dataForm.summaryWeek == 1) {
              this.sumMonthNextText = '下月'
            } else {
              this.sumMonthNextText = '下下月'
            }

            if (this.thisSumWeekAndNext.length == 1) {
              this.sumMonthNextActive = 0;
            } else {
              this.sumMonthNextActive = this.dataForm.summaryWeek
            }
          }
          // 每年
          if (this.dataForm.acquisitionCycleType == '4') {
            // 回显填报周期
            // this.reportYearNextActive = this.dataForm.fillWeek;
            // this.reportYearNextText = this.reportYearAndNext[this.reportMonthNextActive];
            //
            // this.reportYearMonthActive = Number(this.dataForm.fillMonth) - 1;
            // this.reportYearMonthText = '-' + this.reportYearMonthOption[this.reportYearMonthActive].dictValue;
            //
            // this.reportYearDayActive = Number(this.dataForm.fillDay) - 1;
            // this.reportYearDayText = '-' + this.reportYearDayOption[this.reportYearDayActive].dictValue;
            // // 回显汇总周期
            // this.sumYearNextActive = this.dataForm.summaryWeek;
            // this.sumYearNextText = this.sumYearAndNext[this.sumMonthNextActive];
            //
            // this.sumYearMonthActive = Number(this.dataForm.summaryMonth) - 1;
            // this.sumYearMonthText = '-' + this.sumYearMonthOption[this.sumYearMonthActive].dictValue;
            //
            // this.sumYearDayActive = Number(this.dataForm.summaryDayEqs) - 1;
            // this.sumYearDayText = '-' + this.sumYearDayOption[this.sumYearDayActive].dictValue;

            setTimeout(() =>{
              if (this.dataForm.fillWeek == 0) {
                if (this.dataForm.fillMonth != '12' && this.dataForm.fillDay != '31') {
                  this.sumYearAndNext = [{
                    title:'本年',
                    value:'0'
                  },{
                    title:'下年',
                    value:'1'
                  },{
                    title:'下下年',
                    value:'2'
                  }]
                  this.sumYearMonthOption = this.monthOptions.slice(Number(this.dataForm.fillMonth) - 1, this.monthOptions.length);
                  this.sumYearDayOption = this.yearDayOption.slice(Number(this.dataForm.fillDay), this.monthOptions.yearDayOption);
                } else if (this.dataForm.fillMonth != '12' && this.dataForm.fillDay == '31') {
                  this.sumYearAndNext = [{
                    title:'本年',
                    value:'0'
                  },{
                    title:'下年',
                    value:'1'
                  },{
                    title:'下下年',
                    value:'2'
                  }]
                  this.sumYearMonthOption = this.monthOptions.slice(Number(this.dataForm.fillMonth), this.monthOptions.length);
                  this.sumYearDayOption = this.yearDayOption;
                } else if (this.dataForm.fillMonth == '12' && this.dataForm.fillDay != '31') {
                  this.sumYearAndNext = [{
                    title:'本年',
                    value:'0'
                  },{
                    title:'下年',
                    value:'1'
                  },{
                    title:'下下年',
                    value:'2'
                  }]
                  this.sumYearMonthOption = this.monthOptions.slice(Number(this.dataForm.fillMonth) - 1, this.monthOptions.length);
                  this.sumYearDayOption = this.yearDayOption.slice(Number(this.dataForm.fillDay), this.monthOptions.yearDayOption);
                } else if (this.dataForm.fillMonth == '12' && this.dataForm.fillDay == '31') {
                  this.sumYearAndNext = [{
                    title:'下年',
                    value:'1'
                  },{
                    title:'下下年',
                    value:'2'
                  }]
                  this.sumYearMonthOption = this.monthOptions;
                  this.sumYearDayOption = this.yearDayOption;
                }
              } else if (this.dataForm.fillWeek == 1) {
                if (this.dataForm.fillMonth != '12' && this.dataForm.fillDay != '31') {
                  this.sumYearAndNext = [{
                    title:'下年',
                    value:'1'
                  },{
                    title:'下下年',
                    value:'2'
                  }]
                  this.sumYearMonthOption = this.monthOptions.slice(Number(this.dataForm.fillMonth) - 1, this.monthOptions.length);
                  this.sumYearDayOption = this.yearDayOption.slice(Number(this.dataForm.fillDay), this.monthOptions.yearDayOption);
                } else if (this.dataForm.fillMonth != '12' && this.dataForm.fillDay == '31') {
                  this.sumYearAndNext = [{
                    title:'下年',
                    value:'1'
                  },{
                    title:'下下年',
                    value:'2'
                  }]
                  this.sumYearMonthOption = this.monthOptions.slice(Number(this.dataForm.fillMonth), this.monthOptions.length);
                  this.sumYearDayOption = this.yearDayOption;
                } else if (this.dataForm.fillMonth == '12' && this.dataForm.fillDay != '31') {
                  this.sumYearAndNext = [{
                    title:'下年',
                    value:'1'
                  },{
                    title:'下下年',
                    value:'2'
                  }]
                  this.sumYearMonthOption = this.monthOptions.slice(Number(this.dataForm.fillMonth) - 1, this.monthOptions.length);
                  this.sumYearDayOption = this.yearDayOption.slice(Number(this.dataForm.fillDay), this.monthOptions.yearDayOption);
                } else if (this.dataForm.fillMonth == '12' && this.dataForm.fillDay == '31') {
                  this.sumYearAndNext = [{
                    title:'下下年',
                    value:'2'
                  }]
                  this.sumYearMonthOption = this.monthOptions;
                  this.sumYearDayOption = this.yearDayOption;
                }
              }

              if (this.reportYearAndNext.length == 1) {
                this.reportYearNextActive = 0;
              } else {
                this.reportYearNextActive = this.dataForm.fillWeek;
              }

              if (this.sumYearAndNext.length == 1) {
                this.sumYearNextActive = 0;
              } else {
                this.sumYearNextActive = this.dataForm.summaryWeek;
              }

              this.reportYearNextText = this.reportYearAndNext[this.dataForm.fillWeek].title;
              let timeData = this.dataForm.acquisitionCycle.split(',');
              let month = timeData[0];

              this.reportYearMonthOption.forEach((item, index) => {
                if (item.dictKey == this.dataForm.fillMonth) {
                  this.reportYearMonthText = '-' + this.reportYearMonthOption[index].dictValue;
                }
              })
              if (month == this.dataForm.fillMonth) {
                this.reportYearDayOption.forEach((item, index) => {
                  if (item.dictKey == this.dataForm.fillDay) {
                    this.reportYearDayText = '-' + this.reportYearDayOption[index].dictValue;
                  }
                })
              } else {
                this.yearDayOption.forEach((item, index) => {
                  if (item.dictKey == this.dataForm.fillDay) {
                    this.reportYearDayText = '-' + this.yearDayOption[index].dictValue;
                  }
                })
              }

              this.sumYearNextText = this.sumYearAndNext[this.dataForm.summaryWeek].title;
              this.sumYearMonthOption.forEach((item, index) => {
                if (item.dictKey == this.dataForm.summaryMonth) {
                  this.sumYearMonthText = '-' + this.sumYearMonthOption[index].dictValue;
                }
              })
              if (this.dataForm.fillMonth == this.dataForm.summaryMonth) {
                this.sumYearDayOption.forEach((item, index) => {
                  if (item.dictKey == this.dataForm.summaryDayEqs) {
                    this.sumYearDayText = '-' + this.sumYearDayOption[index].dictValue;
                  }
                })
              } else {
                this.yearDayOption.forEach((item, index) => {
                  if (item.dictKey == this.dataForm.summaryDayEqs) {
                    this.sumYearDayText = '-' + this.yearDayOption[index].dictValue;
                  }
                })
              }
            },1000)
          }
          this.getDeptChildTreeData(res);
          templateByEnable(this.userInfo.dept_id).then((res) => {
            this.templateOptions = res.data.data
          })
        }
      })
    },
    // 选择角色查出角色名
    chooseRole(e) {
      this.deptTreeShow = false;
      this.middleShutData = [];
      this.rightShutData = [];
      this.defaultCheckedKeys = [];
      let list = new Array();
      e.forEach((item) => {
        this.roleData.forEach((items) => {
          if (item == items.id) {
            list.push(items.roleName)
          }
        })
      })
      this.dataForm.roleName = list.toString();
      if (this.dataForm.roleId == '') return
      this.getDeptChildTreeData();
    },
    // 删除上传文件
    handleRemove(file, fileList) {
      let list = new Array();
      fileList.forEach((i) => {
        if (i.response) {
          list.push({
            name: i.name,
            url: i.response.data.link
          });
        } else {
          list.push({
            name: i.name,
            url: i.url
          });
        }
      })
      this.fileList = list;
      this.dataForm.attachDescription = JSON.stringify(list);
    },
    // 新增上传文件
    handleSuccess(response, file, fileList) {
      let list = new Array();
      fileList.map((i) => {
        if (i.response) {
          list.push({
            name: i.name,
            url: i.response.data.link
          });
        } else {
          list.push({
            name: i.name,
            url: i.url
          });
        }
      })
      this.fileList = list;
      this.dataForm.attachDescription = JSON.stringify(list);
    },
    // 计算填报单位
    countUnit() {
      let rightName = new Array();
      let rightIds = new Array();
      let middleIds = new Array();
      this.middleShutData.forEach((item) => {
        item.organizeIds.forEach((i) => {
          rightIds.push(i);
        });
        middleIds.push(item.id);
      });
      rightIds = [...new Set(rightIds)];
      this.rightShutIds = this.getArrDifference(middleIds, rightIds);
      this.middleShutData.forEach((item) => {
        if (item.hasChildren == true) {
          this.rightShutIds.push(item.id)
        }
      })
      this.rightShutIds.forEach((item) => {
        let data = this.fndeptChildTreeData.filter((i) => {
          return i.id == item
        })
        rightName.push(data[0])
      })
      this.rightShutData = rightName;
    },
    // 数组取差值
    getArrDifference(arr1, arr2) {
      return arr1.concat(arr2).filter(function (value, i, arr) {
        return arr.indexOf(value) === arr.lastIndexOf(value);
      });
    },
    fnTree(arr) {
      let data = JSON.parse(JSON.stringify(arr));
      let newData = [];
      const callback = (item) => {
        (item.children || (item.children = [])).map((v) => {
          callback(v);
        });
        delete item.children;
        newData.push(item);
      };
      data.map((v) => callback(v));
      return newData;
    },
  }
}
</script>
<style lang="scss" scoped>
.formContentBox {
  padding: 12px;
  height: calc(100% - 76px);
}

.formMain {
  width: 100%;
  overflow: auto;
  height: 100%;
}

.formTopic {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}

.shuttleBackBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  overflow: visible;
}

.shutleBack {
  width: 33%;
  background: #ffffff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
}

.leftRightBtn {
  margin: 211px 12px;
}

.shutleTitle {
  width: 100%;
  background-color: #f5f7fa;
  height: 40px;
  line-height: 40px;
  text-indent: 12px;
  border-bottom: 1px solid #ebeef5;
  font-size: 16px;
}

.shutContent {
  padding: 6px;
  height: 400px;
  overflow: auto;
}

.deptBox {
  line-height: 40px;
  color: #333333;
  border-bottom: 1px solid #ebeef5;
  font-size: 13px;
  display: flex;
}

.unitBox {
  width: 100%;
  display: flex;
  background-color: #f5f7fa;
  border-bottom: 1px solid #ebeef5;
}

.unitTitle {
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  font-size: 16px;
  cursor: pointer;
  border-right: 1px solid #ebeef5;
}

.weekBigBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.leftWeek {
  width: 100px;
  flex-shrink: 0;
  border-right: 1px solid #e4e4e4;
}

.middleMonth {
  width: 100px;
  flex-shrink: 0;
  text-align: center;
  border-right: 1px solid #e4e4e4;
  height: 308px;
  overflow-y: auto;
}

.rightWeek {
  width: calc(100% - 113px);
}

.rightMonth {
  width: calc(100% - 113px);
  height: 250px;
  overflow-y: auto;
}

.thisWeek {
  width: 100%;
  text-align: left;
  height: 28px;
  line-height: 28px;
  color: #606266;
  font-size: 14px;
  cursor: pointer;
}

.thisWeek:hover {
  color: #2979FF;
}

.weekLittleBox {
  width: 100%;
  text-align: center;
  height: 34px;
  line-height: 34px;
  color: #606266;
  font-size: 14px;
  cursor: pointer;
  border-bottom: 1px solid #e4e4e4;
}

.weekLittleBox:hover {
  color: #2979FF;
}

.weekLittleBox:last-child {
  border-bottom: none;
}

::v-deep .el-tabs__header {
  margin: 0;
  border-bottom: none !important;
}
</style>
